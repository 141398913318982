<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'

export default {
  name: 'UserEnrollment',
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    Divisor: () => import('@/components/general/Divisor'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination'),
    InputField: () => import('@/components/general/InputField'),
    SelectField: () => import('@/components/general/SelectField'),
    TextareaField: () => import('@/components/general/TextareaField')
  },

  data () {
    return {
      tabLinks: [
        {
          alias: 'login',
          text: 'community.index:modal.tab.link.user.logins'
        },
        {
          alias: 'email',
          text: 'community.index:modal.tab.link.user.email.logs'
        },
        {
          alias: 'solution',
          text: 'community.index:modal.tab.link.solutions.enrollment'
        },
        {
          alias: 'path',
          text: 'community.index:modal.tab.link.learning.paths.enrollment'
        },
        {
          alias: 'gamification',
          text: 'community.index:modal.tab.link.gamification.data',
          disabled: !this.isGamificationEnabled
        }
      ],

      user: null,
      tabLinkActive: 'solution',
      selectedGamificationTab: 0,
      player: null,
      selectedSolution: null,
      selectedLearningPath: null,
      selectedSession: null,
      availableSessions: [],
      solutionPagination: {
        query: { name: '' },
        filter: {
          themes: null,
          format: null,
          solution_type: null,
          target_audience: null,
          exclusive_to_thematic_pages: 0,
          active: true
        },

        order: [],
        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6,
        is_default_search: true
      },

      enrollmentSolutionPagination: {
        filter: { active: true },
        order: { enrollment_new: 'DESC' },
        page: 1,
        actualPage: 1,
        limit: 10
      },

      enrollmentLearningPathPagination: {
        filter: { active: true },
        order: {
          created_at: 'DESC',
          completed_at: 'DESC',
          name: 'ASC'
        },

        page: 1,
        actualPage: 1,
        limit: 10
      },

      userLoginsPagination: {
        filter: {
          active: true
        },

        order: {
          created_at: 'DESC',
          completed_at: 'DESC',
          name: 'ASC'
        },

        page: 1,
        actualPage: 1,
        limit: 10
      },

      userEmailLogsPagination: {
        filter: {
          active: true,
          user: null
        },

        order: {
          created_at: 'DESC',
          completed_at: 'DESC',
          name: 'ASC'
        },

        page: 1,
        actualPage: 1,
        limit: 50
      },

      permitSubmit: true,
      userCanCancelEnrollment: false,
      cancelForm: false,
      canceledItems: [],
      cancelReason: null,
      cancelDescription: '',
      userEnroll: null
    }
  },

  validations: {
    canceledReason: {
      reason: { required: false },
      area: { required: false }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    ...mapGetters([ 'activeModules' ]),
    isEditing () {
      return this.$route.params.id
    },

    userSessionsList () {
      return this.$store.getters.userSessionsList
    },

    userLoginsList () {
      return this.$store.getters.userLoginsList
    },

    userEmailLogsList () {
      return this.$store.getters.userEmailLogsList
    },

    userLearningPathList () {
      return this.$store.getters.userLearningPathList
    },

    availableSolutions () {
      return this.$store.getters.solutionsList
    },

    canWrite () {
      return this.getContextPermission('enrollment') === 'write'
    },

    isDisabledCancelReason () {
      return (this.cancelReason === 0 && this.cancelDescription.trim().length > 0) || this.cancelReason > 0
    },

    getUserName () {
      return this.$route.params.person ? this.$route.params.person.name : this.user ? this.user.name : ''
    },

    getUserEmail () {
      return this.user ? this.user.email : ''
    },

    getUserUsername () {
      return this.user ? this.user.username : ''
    },

    isGamificationEnabled () {
      return this.activeModules.includes('gamification')
    },

    isCloseSessionEnabled () {
      return this.activeModules.includes('closed-session')
    },

    filteredAvailableSolutions () {
      if (!this.isCloseSessionEnabled) {
        return this.availableSolutions.filter(item => item.sessionType === 'open')
      }

      return this.availableSolutions
    }
  },

  watch: {
    'enrollmentSolutionPagination.page' () {
      if (this.enrollmentSolutionPagination.actualPage !== this.enrollmentSolutionPagination.page) {
        this.getUserSessionsList()
      }
    },

    'enrollmentLearningPathPagination.page' () {
      if (this.enrollmentLearningPathPagination.actualPage !== this.enrollmentLearningPathPagination.page) {
        this.getUserLearningPathList()
      }
    },

    'userLoginsPagination.page' () {
      if (this.userLoginsPagination.actualPage !== this.userLoginsPagination.page) {
        this.getUserLoginsList()
      }
    },

    'userEmailLogsPagination.page' () {
      if (this.userEmailLogsPagination.actualPage !== this.userEmailLogsPagination.page) {
        this.getUserEmailLogsList()
      }
    },

    'solutionPagination.page' () {
      if (this.solutionPagination.actualPage !== this.solutionPagination.page) {
        this.setFetching(true)
        this.searchSolutions()
      }
    },

    'solutionPagination.query.name' () {
      if (this.solutionPagination.query && this.solutionPagination.query.name.length >= 0) {
        this.solutionPagination.page = 1
        this.searchSolutions()
      }
    },

    'selectedSolution' () {
      this.getSessionsBySelectedSolution()
      // },

    // fetching (newValue) {
    //   if (!newValue && !this.enrollmentSolutionPagination.lastPage) {
    //     this.setFetching(true)
    //   }
    }
  },

  created () {
    if (this.canWrite) {
      this.tabLinks.push({
        alias: 'enroll',
        text: 'community.index:modal.tab.link.new.enrollment'
      })
    }

    const userId = this.$route.params.id

    this.attemptGetUser(userId)
      .then(data => {
        this.user = data.data.user
      })

    this.changeTab(this.tabLinkActive)
    this.getSolutions()
    this.userEmailLogsPagination.filter.user = userId
  },

  mounted () {
    // TODO: Descomentar após ajuste do studion
    // this.canCancelEnrollment()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetUser',
      'attemptGetUserSessions',
      'attemptGetUserLogins',
      'attemptGetUserEmailLogs',
      'attemptGetUserLearningPath',
      'attemptGetSolutionsList',
      'attemptGetSessionsBySolutionSlug',
      'attemptEnrollUserInSession',
      'attemptCancelEnrollUserInSession',
      'attemptGetLoggedUser',
      'attemptGetCancellationList',
      'attemptGetCanceledReasonList',
      'attemptGetPlayerProfile'
    ]),

    prevPage (pagination) {
      if (pagination.page > 1) {
        pagination.page -= 1
      }
    },

    nextPage (pagination) {
      pagination.page += 1
    },

    firstPage (pagination) {
      pagination.page = 1
    },

    lastPage (pagination) {
      pagination.page = pagination.lastPage
    },

    changeTab (index) {
      this.tabLinkActive = index
      this.selectedSolution = null
      this.selectedSession = null

      if (index === 'login' && !this.userLoginsPagination.lastPage) {
        this.getUserLoginsList()
      } else if (index === 'email' && !this.userEmailLogsPagination.lastPage) {
        this.getUserEmailLogsList()
      } else if (index === 'solution' && !this.enrollmentSolutionPagination.lastPage) {
        this.getUserSessionsList()
      } else if (index === 'path' && !this.enrollmentLearningPathPagination.lastPage) {
        this.getUserLearningPathList()
      } else if (index === 'enroll') {
        this.getPlayerProfile()
      }
    },

    leaveCancelationForm () {
      this.cancelForm = false
    },

    leave () {
      this.$router.push({ name: 'users.index' })
    },

    selectSolution (id) {
      this.selectedSolution = id === this.selectedSolution ? null : id
    },

    selectSession (id) {
      this.selectedSession = id === this.selectedSession ? null : id
    },

    getUserSessionsList () {
      this.setFetching(true)

      this.attemptGetUserSessions({
        userId: this.$route.params.id,
        pagination: this.enrollmentSolutionPagination
      })
        .then(pagination => {
          this.$set(this.enrollmentSolutionPagination, 'lastPage', pagination.lastPage)
          this.enrollmentSolutionPagination.actualPage = pagination.page
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getUserLearningPathList () {
      this.setFetching(true)

      this.attemptGetUserLearningPath({
        userId: this.$route.params.id,
        pagination: this.enrollmentLearningPathPagination
      })
        .then(pagination => {
          this.enrollmentLearningPathPagination.lastPage = pagination.lastPage
          this.enrollmentLearningPathPagination.actualPage = pagination.page
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getUserLoginsList () {
      this.setFetching(true)

      this.attemptGetUserLogins({
        userId: this.$route.params.id,
        pagination: this.userLoginsPagination
      })
        .then(pagination => {
          this.$set(this.userLoginsPagination, 'lastPage', pagination.lastPage)
          this.userLoginsPagination.actualPage = pagination.page
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getUserEmailLogsList () {
      this.setFetching(true)

      this.attemptGetUserEmailLogs({ pagination: this.userEmailLogsPagination })
        .then(pagination => {
          this.$set(this.userEmailLogsPagination, 'lastPage', pagination.lastPage)
          this.userEmailLogsPagination.actualPage = pagination.page
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getStatusTag (item) {
      if (!item) return

      const statusTag = {
        text: '',
        class: ''
      }

      if (item && item.status) {
        if (item.status === 'delivery') {
          statusTag.text = 'Entregue'
          statusTag.class = 'active-ribbon'
        } else if (item.status === 'bounce') {
          statusTag.text = 'Rejeitado'
          statusTag.class = 'inactive-ribbon'
        } else if (item.status === 'spam_complaint') {
          statusTag.text = 'Spam'
          statusTag.class = 'warning-ribbon'
        } else {
          statusTag.text = 'Incompleto'
          statusTag.class = 'inactive-ribbon'
        }
      } else {
        statusTag.text = 'Incompleto'
        statusTag.class = 'inactive-ribbon'
      }

      return statusTag
    },

    getSolutions () {
      this.setFetching(true)

      this.solutionPagination.is_default_search = this.solutionPagination.page === 1 &&
       this.solutionPagination.query.name.length === 0

      this.attemptGetSolutionsList(this.solutionPagination)
        .then(pagination => {
          this.solutionPagination.lastPage = pagination.lastPage
          this.solutionPagination.actualPage = pagination.page
        })
        .finally(() => {
          this.permitSubmit = true
          this.setFetching(false)
        })
    },

    getSessions (slug) {
      this.setFetching(true)

      this.attemptGetSessionsBySolutionSlug({
        slug: slug,
        userId: this.$route.params.id
      })
        .then(resp => {
          this.availableSessions = resp.data.filter((session) => {
            return session.enrollment
          })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getSessionsBySelectedSolution () {
      if (this.selectedSolution) {
        this.selectedSession = null
        const solution = this.availableSolutions.filter((solution) => {
          return solution.id === this.selectedSolution
        })

        this.getSessions(solution[0].slug)
      } else {
        this.availableSessions = []
      }
    },

    getPlayerProfile () {
      this.setFetching(true)

      this.attemptGetPlayerProfile(this.getUserUsername)
        .then(data => {
          this.player = data.data
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getStatusClass (status) {
      return `status-${status}`
    },

    enroll () {
      if (!this.permitSubmit) {
        return
      }

      if (!this.selectedSession) {
        this.setFeedback({ message: this.$t('user.enrollment.session:session.not.selected') })

        return
      }

      this.setFetching(true)

      this.attemptEnrollUserInSession({
        sessionId: this.selectedSession,
        userId: this.$route.params.id
      })
        .then(() => {
          this.getSessionsBySelectedSolution()
          this.setFeedback({ message: this.$t('user.enrollment.session:success') })
          this.getUserSessionsList()
        })
        .catch(({ data }) => {
          let message = this.$t('global.error')

          if (data && data.error) {
            switch (data.error.message) {
              case 'pushal_user_phone_not_found':
                message = this.$t('user.enrollment:phone.error')
                break
              case 'cannot_enroll_solution_exclusive_company':
                message = this.$t('user.enrollment:company.error')
                break
              case 'cannot_enroll_solution_enrollment_unavailable':
                message = this.$t('user.enrollment:unavailable.error')
                break
              case 'no_vacancies_for_session':
                message = this.$t('user.enrollment:session.vacancy.error')
                break
              case 'user_most_await_to_enroll':
                message = this.$t('user.enrollment:re.enrollment.error')
                break
              case 'cannot_enroll_session_in_progress':
                message = this.$t('user.enrollment:session.in.progress.error')
                break
              case 'cannot_enroll_session_ended':
                message = this.$t('user.enrollment:session.ended.error')
                break
              case 'session_integration_not_found':
                message = this.$t('user.enrollment:session.integration.error')
                break
              case 'user_already_enrolled':
              case 'user_already_enrolled_session':
                message = this.$t('user.enrollment:already.enrolled')
                break
            }
          }

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    searchSolutions () {
      this.permitSubmit = false

      this.debounceEvent(() => {
        this.getSolutions()
      }, 1500)
    },

    dateFormat (datetime) {
      if (datetime === '' || datetime === undefined || datetime === null) {
        return datetime
      }

      const date = new Date(Date.parse(datetime))

      return Intl.DateTimeFormat('pt-BR')
        .format(date)
    },

    getCompletionDateTooltip (completionDate) {
      if (!completionDate) { return null }

      return `Concluído em:<br/><b>${this.dateFormat(completionDate)}</b>`
    },

    specificDateFormat (datetime) {
      return format(new Date(datetime), "dd/MM/yyyy ' - ' HH:mm:ss")
    },

    getProgressLearningPath (learningPathUser) {
      const total = learningPathUser.learningPath.learningPathSolutions.filter((item) => {
        return item.solution.active === 1
      }).length
      const finished = learningPathUser.sessionUser.filter((session) => {
        return session.status === 'finished'
      }).length

      return `${finished}/${total} concluídos`
    },

    canBeCanceled (status) {
      return [
        'initial',
        'in_progress',
        'enrolled'
      ].find(statusCheck => statusCheck === status)
    },

    openCancelForm (enroll) {
      this.cancelForm = true
      this.userEnroll = enroll
      this.getCancellationList()
    },

    cancelEnroll () {
      const data = {
        sessionUserId: this.userEnroll.id,
        id: this.cancelReason,
        description: this.cancelDescription || ''
      }

      this.setFetching(true)

      this.attemptCancelEnrollUserInSession(data)
        .then(() => {
          this.getUserSessionsList()
          this.setFeedback({ message: this.$t('user.enrollment.session.canceled:success') })
        })
        .catch(({ data }) => {
          let message = this.$t('global.error')

          if (data && data.error) {
            message = this.$t('global.error')

            switch (data.error.message) {
              case 'enrollment_not_found':
                message = this.$t('user.enrollment:enrollment.error')
                break
              case 'enrollment_cannot_be_canceled':
                message = this.$t('user.enrollment:cannot.canceled.error')
                break
            }
          }

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
          this.cancelForm = false
        })
    },

    getCancellationList () {
      this.setFetching(true)

      this.attemptGetCancellationList()
        .then(data => {
          this.canceledItems = data

          this.canceledItems.push({
            text: 'Outros',
            value: 0
          })
        })
        .finally(() => {
          this.permitSubmit = true
          this.setFetching(false)
        })
    },

    // TODO: Descomentar após ajuste do studion
    // canCancelEnrollment () {
    //   this.attemptGetLoggedUser()
    //     .then((data) => {
    //       const permission = {}

    //       data.user.userProfile[0].profile.access.forEach(function (key) {
    //         if (key.contextName === 'enrollment') {
    //           permission[key.contextName] = key.contextAccess
    //         }
    //       })

    //       this.userCanCancelEnrollment = permission.enrollment === 'write'
    //     })
    // },

    canceledReason (item) {
      if (item.SessionCancellation.length) {
        return item.SessionCancellation[0].CancellationReason.name
      }
    },

    tooltipContent (item) {
      if (!item.SessionCancellation.length) {
        return
      }

      const date = format(
        new Date(item.SessionCancellation[0].createdAt),
        "'Cancelado em' dd/MM/yyyy ' às ' HH:mm"
      )

      const responsible = item.SessionCancellation[0].User.name
      const msg = item.SessionCancellation[0].CancellationReason.name

      return `
        <span>${date}</span></br>
        <span>Por ${responsible}</span></br>
        <p>${msg}</p>
      `
    },

    getActionDateFormatting (actionDate) {
      const date = format(
        new Date(actionDate),
        "'Adquirido em' dd/MM/yyyy ' às ' HH:mm"
      )

      return date
    },

    isImpersonate (item) {
      if (item.type === 'impersonate') {
        return item.adminUser ? item.adminUser.name : '-'
      }

      return this.$t('global:no')
    },

    aliasType (item) {
      switch (item) {
        case 'refresh':
          return this.$t('user.enrollment.user.logins.list:datatable.content.1')
        case 'login':
          return this.$t('user.enrollment.user.logins.list:datatable.content.2')
        case 'impersonate':
          return this.$t('user.enrollment.user.logins.list:datatable.content.3')
        default :
          return item
      }
    }
  }

}
</script>

<template>
  <Modal
    :active="true"
    @close="leave()"
  >
    <div class="modal-form modal-user-enrollment">
      <span class="modal-subtitle">{{ $t('community:modal.subtitle.user.enrollment') }}</span>
      <h2 class="modal-title">
        {{ getUserName }}
      </h2>
      <Tabs
        slot="tabs"
        :links="tabLinks"
        :alias-active="tabLinkActive"
        dark
        use-alias
        @tabChange="changeTab"
      />
      <form @submit.prevent>
        <template v-if="isEditing">
          <template v-if="tabLinkActive === 'login' && userLoginsList.length">
            <Datatable
              class="logins-list"
              :items="userLoginsList"
              dark
            >
              <template slot="thead">
                <tr>
                  <th class="th">
                    {{ $t('user.enrollment.user.logins.list:datatable.header.1') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.user.logins.list:datatable.header.2') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.user.logins.list:datatable.header.3') }}
                  </th>
                </tr>
              </template>
              <template
                slot="tbody"
                slot-scope="props"
              >
                <tr>
                  <td class="td">
                    <span class="td-text bolder">{{ aliasType(props.item.type) }}</span>
                  </td>
                  <td class="td text-center column-20">
                    <span class="td-text">{{ specificDateFormat(props.item.createdAt) }}</span>
                  </td>
                  <td class="td text-center column-20">
                    <span class="td-text bolder">{{ isImpersonate(props.item) }}</span>
                  </td>
                </tr>
              </template>
            </Datatable>

            <Pagination
              :active-page="userLoginsPagination.page"
              :page-count="userLoginsPagination.lastPage"
              dark
              @firstPage="firstPage(userLoginsPagination)"
              @lastPage="lastPage(userLoginsPagination)"
              @nextPage="nextPage(userLoginsPagination)"
              @previousPage="prevPage(userLoginsPagination)"
            />
          </template>

          <template v-if="tabLinkActive === 'email' && userEmailLogsList.length">
            <p class="modal-list__subtitle">
              {{ $t('user.enrollment.user.email.logs.list:datatable.subheader.1', { user: getUserName } ) }}
            </p>

            <Datatable
              class="email-logs-list"
              :items="userEmailLogsList"
              dark
            >
              <template slot="thead">
                <tr>
                  <th class="th">
                    {{ $t('user.enrollment.user.email.logs.list:datatable.header.1') }}
                  </th>
                  <th
                    class="th text-center"
                    width="240"
                  >
                    {{ $t('user.enrollment.user.email.logs.list:datatable.header.2') }}
                  </th>
                  <th
                    class="th text-center"
                    width="120"
                  >
                    {{ $t('user.enrollment.user.email.logs.list:datatable.header.3') }}
                  </th>
                </tr>
              </template>

              <template
                slot="tbody"
                slot-scope="props"
              >
                <tr>
                  <td class="td">
                    <span class="td-text bolder">{{ props.item.subject }}</span>
                  </td>
                  <td class="td text-center">
                    <span class="td-text">{{ specificDateFormat(props.item.date) }}</span>
                  </td>
                  <td class="td text-center">
                    <span
                      class="td-tag"
                      :class="getStatusTag(props.item).class"
                    >{{ getStatusTag(props.item).text }}</span>
                  </td>
                </tr>
              </template>
            </Datatable>

            <Pagination
              :active-page="userEmailLogsPagination.page"
              :page-count="userEmailLogsPagination.lastPage"
              dark
              @firstPage="firstPage(userEmailLogsPagination)"
              @lastPage="lastPage(userEmailLogsPagination)"
              @nextPage="nextPage(userEmailLogsPagination)"
              @previousPage="prevPage(userEmailLogsPagination)"
            />
          </template>

          <template v-if="tabLinkActive === 'solution' && userSessionsList.length">
            <Datatable
              v-if="!cancelForm"
              :items="userSessionsList"
              dark
            >
              <template slot="thead">
                <tr>
                  <th class="th">
                    {{ $t('user.enrollment.solutions.list:datatable.header.1') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.solutions.list:datatable.header.2') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.solutions.list:datatable.header.3') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.solutions.list:datatable.header.4') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.solutions.list:datatable.header.5') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.solutions.list:datatable.header.6') }}
                  </th>
                </tr>
              </template>
              <template
                slot="tbody"
                slot-scope="props"
              >
                <tr>
                  <td class="td">
                    <span class="td-text bolder">{{ props.item.session.solution.name }}</span>
                  </td>
                  <td class="td text-center">
                    <span class="td-text">
                      {{ props.item.session.solution.sessionType === 'closed' ? props.item.session.name : '-' }}
                    </span>
                  </td>
                  <td class="td text-center">
                    <span class="td-text">{{ dateFormat(props.item.createdAt) }}</span>
                  </td>
                  <td class="td text-center">
                    <span class="td-text">{{ dateFormat(props.item.closingDate) }}</span>
                  </td>

                  <td class="td text-center status">
                    <span
                      v-tooltip="{
                        content: props.item.status === 'finished' ?
                          getCompletionDateTooltip(props.item.completedAt) :
                          null,
                        placement: 'top-center',
                        classes: ['info', 'status-tooltip'],
                        offset: 0
                      }"
                      class="td-tag status-tag"
                      :class="getStatusClass(props.item.status)"
                    >
                      {{ $t('user.enrollment.status:' + props.item.status) }}
                    </span>
                  </td>

                  <td
                    v-if="canBeCanceled(props.item.status)"
                    class="td td-button td-small text-right"
                  >
                    <Action
                      v-if="userCanCancelEnrollment"
                      class="cancel-button"
                      type="button"
                      primary
                      tiny
                      dark
                      :text="$t('global:cancel')"
                      @click="openCancelForm(props.item)"
                    />
                  </td>

                  <td
                    v-else
                    class="td text-center"
                  >
                    <button
                      v-tooltip="{
                        content: tooltipContent(props.item),
                        placement: 'bottom-center',
                        classes: ['info', 'status-tooltip'],
                        targetClasses: ['it-has-a-tooltip'],
                        offset: 15
                      }"
                      type="button"
                      class="td-text"
                    >
                      {{ canceledReason(props.item) ? $t('user.enrollment.solutions.list:cancel.reason') : ' - ' }}
                    </button>
                  </td>
                </tr>
              </template>
            </Datatable>
            <div v-else>
              <span class="modal-subtitle">
                {{ $t('user.enrollment.solutions.list:cancel.header.1') }}
              </span>

              <form @submit.prevent>
                <div class="canceled-screen">
                  <SelectField
                    v-model="cancelReason"
                    :label="$t('user.enrollment.solutions.list:cancel.reason')"
                    :items="canceledItems"
                    :validation="$v.canceledReason.reason"
                    dark
                  />

                  <TextareaField
                    v-if="cancelReason === 0"
                    v-model="cancelDescription"
                    class="canceled-screen--textarea"
                    :label="$t('global:form.solutions.description')"
                    :validation="$v.canceledReason.area"
                    auto-grow
                    :counter="30"
                  />
                </div>

                <div class="form-submit text-center button-bars">
                  <Action
                    :text="$t('global:back')"
                    type="button"
                    secondary
                    large
                    fixed-width
                    @click="leaveCancelationForm()"
                  />

                  <Action
                    :text="$t('global:confirm')"
                    type="button"
                    secondary
                    large
                    fixed-width
                    :disabled="!isDisabledCancelReason"
                    @click="cancelEnroll()"
                  />
                </div>
              </form>
            </div>
            <Pagination
              :active-page="enrollmentSolutionPagination.page"
              :page-count="enrollmentSolutionPagination.lastPage"
              dark
              @firstPage="firstPage(enrollmentSolutionPagination)"
              @lastPage="lastPage(enrollmentSolutionPagination)"
              @nextPage="nextPage(enrollmentSolutionPagination)"
              @previousPage="prevPage(enrollmentSolutionPagination)"
            />
          </template>

          <template v-if="tabLinkActive === 'path' && userLearningPathList.length">
            <Datatable
              :items="userLearningPathList"
              dark
            >
              <template slot="thead">
                <tr>
                  <th class="th">
                    {{ $t('user.enrollment.learning.path.list:datatable.header.1') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.learning.path.list:datatable.header.2') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.learning.path.list:datatable.header.3') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.learning.path.list:datatable.header.4') }}
                  </th>
                  <th class="th text-center">
                    {{ $t('user.enrollment.learning.path.list:datatable.header.5') }}
                  </th>
                </tr>
              </template>
              <template
                slot="tbody"
                slot-scope="props"
              >
                <tr>
                  <td class="td">
                    <span class="td-text bolder">{{ props.item.learningPath.name }}</span>
                  </td>
                  <td class="td text-center">
                    <span class="td-text">{{ getProgressLearningPath(props.item) }}</span>
                  </td>
                  <td class="td text-center">
                    <span class="td-text">{{ dateFormat(props.item.createdAt) }}</span>
                  </td>
                  <td class="td text-center">
                    <span class="td-text">{{ props.item.completedAt ? dateFormat(props.item.completedAt) : '-' }}</span>
                  </td>
                  <td class="td text-center">
                    <span
                      v-tooltip="{
                        content: props.item.status === 'finished' ?
                          getCompletionDateTooltip(props.item.completedAt) :
                          null,
                        placement: 'top-center',
                        classes: ['info', 'status-tooltip'],
                        offset: 0
                      }"
                      class="td-tag status-tag"
                      :class="getStatusClass(props.item.status)"
                    >
                      {{ $t('user.enrollment.status:' + props.item.status) }}
                    </span>
                  </td>
                </tr>
              </template>
            </Datatable>
            <Pagination
              :active-page="enrollmentLearningPathPagination.page"
              :page-count="enrollmentLearningPathPagination.lastPage"
              dark
              @firstPage="firstPage(enrollmentLearningPathPagination)"
              @lastPage="lastPage(enrollmentLearningPathPagination)"
              @nextPage="nextPage(enrollmentLearningPathPagination)"
              @previousPage="prevPage(enrollmentLearningPathPagination)"
            />
          </template>

          <template v-if="isGamificationEnabled && tabLinkActive === 'gamification'">
            <div
              v-if="player"
              class="gamification"
            >
              <div class="gamification-resume">
                <div
                  v-if="player.rankings && player.rankings.leaderboard_main"
                  class="resume-position"
                >
                  <p>Posição</p>
                  <b>{{ player.rankings.leaderboard_main.positiion }}°</b>
                  <small>*entre usuários ativos</small>
                </div>

                <div class="resume-level-points">
                  <div
                    v-if="player.level"
                    class="resume-level"
                  >
                    <p>Nível</p>
                    <b>{{ player.level.position }}</b>
                  </div>

                  <div
                    v-if="player.totalPoints"
                    class="resume-points"
                  >
                    <p>Pontos</p>
                    <b>{{ player.totalPoints }}</b>
                  </div>
                </div>

                <div class="resume-medals">
                  <p>Medalhas adquiridas</p>

                  <div class="medals">
                    <ul>
                      <li
                        v-for="(medal, medalIndex) in player.achievements"
                        :key="medalIndex"
                        class="medal"
                        :class="{'active': medal.metadata.owned}"
                      >
                        <div class="medal-icon">
                          <img
                            :src="medal.image"
                            :alt="medal.name"
                            :title="medal.name"
                          >
                        </div>

                        <p class="medal-text">
                          {{ medal.name }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="gamification-extract">
                <h1>Extrato de Pontos</h1>

                <div class="extract-tabs">
                  <div
                    class="tab tab-gains"
                    :class="{'tab-active': selectedGamificationTab === 0}"
                    @click="selectedGamificationTab = 0"
                  >
                    Ganhos
                  </div>
                  <div
                    class="tab tab-exchanges"
                    :class="{'tab-active': selectedGamificationTab === 1}"
                    @click="selectedGamificationTab = 1"
                  >
                    Trocas
                  </div>
                </div>

                <div class="extract-lists">
                  <div
                    v-if="selectedGamificationTab === 0"
                    class="list-gains"
                  >
                    <Datatable
                      v-if="player.actions && player.actions.length > 0"
                      :items="player.actions"
                    >
                      <template
                        slot="tbody"
                        slot-scope="props"
                      >
                        <tr>
                          <td class="td">
                            <span class="td-text">{{ props.item.action.description || props.item.action.name }}</span><br>
                            <i class="td-subtext">{{ getActionDateFormatting(props.item.created) }}</i>
                          </td>

                          <td class="td">
                            <span class="td-text td-italic">+{{ props.item.action.totalPoints }}pts</span>
                          </td>
                        </tr>
                      </template>
                    </Datatable>

                    <i v-if="!player.actions || (player.actions && player.actions.length === 0)">Nenhuma ação encontrada para este usuário.</i>
                  </div>

                  <div
                    v-if="selectedGamificationTab === 1"
                    class="list-exchanges"
                  >
                    <Datatable
                      v-if="player.exchanges && player.exchanges.length > 0"
                      :items="player.exchanges"
                    >
                      <template
                        slot="tbody"
                        slot-scope="props"
                      >
                        <tr>
                          <td class="td">
                            <span class="td-text">{{ props.item.exchanges.description }}</span><br>
                            <i class="td-subtext">{{ getActionDateFormatting(props.item.created) }}</i>
                          </td>

                          <td class="td">
                            <span class="td-text td-italic">+{{ props.item.exchanges.totalPoints }}pts</span>
                          </td>
                        </tr>
                      </template>
                    </Datatable>

                    <i v-if="!player.exchanges || (player.exchanges && player.exchanges.length === 0)">Em breve.</i>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-if="tabLinkActive === 'enroll' && canWrite">
            <div class="search-field">
              <InputField
                v-model="solutionPagination.query.name"
                :label="$t('user.enrollment:search.solutions')"
                dark
              />
            </div>
            <div class="link-table">
              <div class="solutions-available">
                <Datatable
                  :items="filteredAvailableSolutions"
                  hide-horizontal-line
                  dark
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('community.solutions.list:datatable.header.1') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr :class="{'active': selectedSolution === props.item.id}">
                      <td
                        class="td td-full"
                        @click="selectSolution(props.item.id)"
                      >
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td
                        class="td td-button td-small text-right"
                        @click="selectSolution(props.item.id)"
                      >
                        <Action
                          type="button"
                          icon="keyboard_arrow_right"
                          flat
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
                <Pagination
                  :active-page="solutionPagination.page"
                  :page-count="solutionPagination.lastPage"
                  dark
                  @firstPage="firstPage(solutionPagination)"
                  @lastPage="lastPage(solutionPagination)"
                  @nextPage="nextPage(solutionPagination)"
                  @previousPage="prevPage(solutionPagination)"
                />
              </div>
              <Divisor orientation="vertical" />
              <div class="sessions-available">
                <Datatable
                  :items="availableSessions"
                  hide-horizontal-line
                  dark
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('community.solutions.list:datatable.header.2') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>
                  <template
                    v-if="availableSessions.length > 0"
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      :class="props.item.enrolled ?
                        {'inactive': true} :
                        {'active': selectedSession === props.item.id}"
                    >
                      <td
                        class="td td-full"
                        @click="selectSession(props.item.id)"
                      >
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                    </tr>
                  </template>
                  <template
                    v-if="availableSessions.length === 0"
                    slot="tbody"
                  />
                </Datatable>
              </div>
            </div>
            <Action
              :text="$t('global:enroll')"
              type="button"
              secondary
              large
              submit
              fixed-width
              @click="enroll()"
            />
          </template>

          <template v-if="tabLinkActive === 'email' && !userEmailLogsList.length">
            <p class="modal-list__empty-message">
              {{ $t('user.enrollment.user.email.logs.list:empty.message', { user: getUserEmail } ) }}
            </p>
          </template>

          <template v-if="tabLinkActive === 'solution' && !userSessionsList.length">
            <p class="modal-list__empty-message">
              {{ $t('user.enrollment.solutions.list:empty.message') }}
            </p>
          </template>

          <template v-if="tabLinkActive === 'path' && !userLearningPathList.length">
            <p class="modal-list__empty-message">
              {{ $t('user.enrollment.learning.path.list:empty.message') }}
            </p>
          </template>
        </template>
      </form>
    </div>
  </Modal>
</template>

<style lang="scss" src="../assets/scss/Gamification.scss"></style>

<style>
.modal-user-enrollment .tabs-link {
  text-transform: none;
}

.tooltip {
  color: #005EB8;
  z-index: 9999!important;
  font-size: 0.85rem;
}

.tooltip p {
  margin-top: 5px;
}

.tooltip.info {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-width: 500px;
  padding: 24px;
}

.tooltip.info.status-tooltip {
  background: white;
  text-align: center;
  padding: 12px 24px;
}

.tooltip .info .tooltip-arrow {
  border-color: #005EB8;
}

.form-submit.button-bars button {
  margin: 0 20px;
}

.canceled-screen {
  width: 440px;
}

.datatable-wrapper.theme-dark .datatable .td:not(:first-child, :last-child) {
  padding-right: 10px;
  padding-left: 10px;
}

.td.status {
  white-space: nowrap;
}

.modal-user-enrollment .td-tag {
  width: 100%;
  font-weight: bold;
}

/* .modal-user-enrollment .status-tag.td-tag {
  font-weight: bold;
  color: #ffffff;
  border-radius: 0;
  font-size: 12px;
} */
.modal-user-enrollment .td-tag.status-canceled,
.modal-user-enrollment .td-tag.status-expired,
.modal-user-enrollment .td-tag.status-dropout,
.modal-user-enrollment .td-tag.status-initial {
  background-color: rgba(255, 255, 255, .5);
}
.modal-user-enrollment .td-tag.status-in_progress {
  background-color: #ffffbe;
}
.modal-user-enrollment .td-tag.status-finished {
  background-color: #beffbe;
}
.modal-user-enrollment .modal-list__subtitle,
.modal-user-enrollment .modal-list__empty-message {
  color: #ffffff;
}
.modal-user-enrollment .profiles-selection {
  margin-top: 60px;
}
.modal-user-enrollment.modal-form .btn-flat {
  margin-bottom: 0;
}
.modal-user-enrollment.modal-form form {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-user-enrollment.modal-form .datatable-wrapper,
.modal-user-enrollment.modal-form form .link-table {
  width: 100%;
  margin-bottom: 60px;
}
.modal-user-enrollment.modal-form .link-table .datatable {
  margin-top: 0;
}
.modal-user-enrollment.modal-form .link-table .datatable .td {
  cursor: pointer;
  border: 0;
}
.modal-user-enrollment.modal-form .link-table .datatable tr {
  background-color: rgba(255, 255, 255, 0);
  transition: 60ms background-color ease;
}

.modal-user-enrollment.modal-form .link-table .datatable tr.active,
.modal-user-enrollment.modal-form .link-table .datatable tr:hover {
  background-color: rgba(255, 255, 255, 1);
}
.modal-user-enrollment.modal-form .link-table .datatable tr.active .icon,
.modal-user-enrollment.modal-form .link-table .datatable tr.active .td-text,
.modal-user-enrollment.modal-form .link-table .datatable tr:hover .td-text {
  color: rgba(0, 0, 0, .8);
}

.modal-user-enrollment.modal-form .link-table .datatable tr.inactive,
.modal-user-enrollment.modal-form .link-table .datatable tr.inactive tr:hover {
  background-color: rgb(142, 142, 142);
  cursor: not-allowed;
}
.modal-user-enrollment.modal-form .link-table .datatable tr.inactive .icon,
.modal-user-enrollment.modal-form .link-table .datatable tr.inactive .td-text,
.modal-user-enrollment.modal-form .link-table .datatable tr:hover .td-text {
  color: rgba(0, 0, 0, .8);
}

.modal-user-enrollment .search-field {
  align-self: flex-start;
}

.canceled-screen .form-label,
.canceled-screen .form-input,
.canceled-screen .form-input-counter {
  color: #ffffff;
}

.canceled-screen .form-item.has-value.has-floating-label .form-label,
.canceled-screen .form-item.has-focus.has-floating-label .form-label {
    color: #ffffff;
}

.logins-list tr:first-child {
  padding-left: 20px;
}

.logins-list th, .logins-list td span {
  padding-top: 10px;
}

.column-20 {
  width: 20%;
}
</style>
